import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import { TextToggle } from "../components/toggles"
import Icon from "../components/icon"
import _filter from "lodash/filter"
import _sortBy from "lodash/sortBy"

const Section = (props) => (
	<section key={ props.title } className='mb4 mb5-ns pt3 pt0-ns pr4-ns pr5-l'>
		<h3 className='fw4 f6 ttu tracked black-50'>{ props.title }</h3>
		<div>
		{
			props.items.map((o, idx) => <TextToggle key={ idx } title={ o.question }>{ o.answer }</TextToggle> )
		}
		</div>
	</section>
)

class FAQ extends React.Component {
	prep(questions, category) {
		let filtered = _filter(questions, ['category', category]);
		return _sortBy(filtered, 'sort');
	}
	render() {
		const questions = this.props.data.questions.edges.map(({node}) => node );
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns'>
					<PageTitle title="FAQs" />
					<p className='i ma0 pa0 pv2-l pv2-xl black-90'>Question not here? <Link className='blue no-underline hover-near-black' to="/contact">Get in touch.</Link> We're happy to help.</p>
					<div className='mv3 cf'>
						<div className='w-100 w-50-m w-50-l fl'>
							<Section title="Shipping" items={ this.prep(questions, 'Shipping') } />
							<Section title="Products" items={ this.prep(questions, 'Products') } />
						</div>
						<div className='w-100 w-50-m w-50-l fl'>
							<Section title="Orders & Returns" items={ this.prep(questions, 'Orders & Returns') } />
							<Section title="Custom Work" items={ this.prep(questions, 'Custom Work') } />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export const query = graphql`
	query Questions {
		questions: allAirtableQuestions {
			edges {
				node {
					question
					answer
					category
					sort
				}
			}
		}
  }
`

export default FAQ;