import React from "react"
import Icon from "../components/icon";
import "../tachyons.min.css"

class BorderToggle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}
	}
	toggle = () => {
		const open = this.state.open;
		this.setState({
			open: !open
		})
	}
	render() {
		const arrow = this.state.open ? "chevron-up" : "chevron-down";
		return (
			<div className='ba b--black-90 dim pointer fadeInUp br1' onClick={ this.toggle }>
				<div className='ph3 pv2'>
					<div className='w-80 dib v-mid'>
						<p className='f5 black-80 ma0 pa0'>{ this.props.title }</p>
					</div><div className='w-20 dib tr v-mid'>
						<Icon className='black-50' icon={ arrow } style={{ width: 20, height: 20 }} />
					</div>
				</div>
				{ this.state.open &&
					<p className='black-80 ph3'>{ this.props.children }</p>
				}
			</div>
		)
	}
}


class TextToggle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		}
	}
	render() {
		const arrow = this.state.open ? "chevron-up" : "chevron-down";
		return (
			<div className='mb3 mb4-ns'>
				<p className='f5 f4-ns black-90 pa0 pointer dim' onClick={ () => this.setState({ open: !this.state.open }) }>
					{ this.props.title } <Icon className='v-mid black-50' icon={ arrow } style={{ width: 18, height: 18 }} />
				</p>
				{
					this.state.open &&
					<p className='black-80 pa0 lh-copy pb2 mb2'><span className='pre-wrap'>{ this.props.children }</span></p>
				}
			</div>
		)
	}
}

export { BorderToggle, TextToggle };